<script lang="ts">
export default defineComponent({
     components: {
    },
    data:()=>( {
       
    }), 
    setup(){
        const craving = ref();
        const animate = ref(false);
        if (process.client) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    animate.value = entry.isIntersecting;
                    console.log("animate cravings",  animate.value)
                },
                {
                    threshold: .1, 
                }
            );
            onMounted(() => {
                observer.observe(craving.value);
            });
        }
        return {
            animate,
            craving
        }
    }
});
</script>
<template>
    <div ref="craving" class="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg py-10 mx-auto gap-0 items-center px-10 md:px-0 overflow-x-hidden"> 
        <div class="relative w-full">
            <!-- <div class="bg-gray-200 mt-3 rounded-xl w-full h-full absolute"></div> -->
            <div class="grid grid-cols-3 scale-[132%] md:scale-100 pt-10 pb-14 translate-x-8 ">
               
                <div class="w-full aspect-[251/544] relative">
                    <transition name="device1" >
                        <div v-if="animate" class="w-full h-full absolute translate-x-[10%] z-30 ">
                            <nuxt-img format="webp" :src="$t('home.features.craving.image_craving_1')" width="240" height="520" quality="80" loading="lazy" alt="smoxy app screen craving strength" class="bg-white device-image-sm" /> 
                        </div>
                    </transition>
                </div> 
               
                <div class="w-full aspect-[251/544] relative">
                    <transition name="device2" >
                        <div v-if="animate" class="scale-90 w-full h-full absolute -translate-x-[18%] md:translate-y-3 z-10">
                            <nuxt-img format="webp" :src="$t('home.features.craving.image_craving_2')" width="240" height="520" quality="80" loading="lazy" alt="smoxy app screen craving mood" class="bg-gray-100 device-image-sm" /> 
                        </div>
                    </transition>
                </div>  
                <div class="w-full aspect-[251/544] relative">
                    <transition name="device3" >
                        <div v-if="animate" class="scale-[.80] w-full h-full absolute -translate-x-[50%] md:translate-y-6 z-0">
                            <nuxt-img format="webp" :src="$t('home.features.craving.image_craving_3')" width="240" height="520" quality="80" loading="lazy" alt="smoxy app screen craving trigger" class="bg-gray-200 device-image-sm" /> 
                        </div>
                    </transition>
                </div>            
            </div>
        </div>
                
        <div class="pt-6 md:pt-0 px-4 md:px-0">
            <p class="text-[#7E1EF8]">{{$t('home.features.craving.title')}}</p>
            <h3 class="text-3xl pr-4 font-bold font-owners text-[#290B50]">{{$t('home.features.craving.headline')}}</h3>
            <p class="pt-4">{{$t('home.features.craving.body')}} </p>
        </div>  

    </div>
</template>

<style>

.device1,
.device2,
.device3 {
    position: relative;
}
.device1-enter-active, 
.device1-leave-active {
    transition: transform 500ms ease 0s, scale 500ms ease 0s, opacity 100ms ease 0s;
}
/* 
transition: background-color 1s linear 2s, color 1s;
transition: property name | duration | timing function | delay
 */
.device2-enter-active, 
.device2-leave-active {
    transition: transform 500ms ease 500ms, scale 500ms ease 500ms, opacity 100ms ease 500ms;
}
.device3-enter-active, 
.device3-leave-active {
    transition: transform 500ms ease 1000ms, scale 500ms ease 1000ms, opacity 100ms ease 1000ms;
}

.device1-enter-from,
.device1-leave-to {
    opacity: 0.0;
    transform: translateX(-100px);
}

.device2-enter-from,
.device2-leave-to {
    opacity: 0.0;
    transform:  translateX(-90%) scale(.9);
}

.device3-enter-from,
.device3-leave-to {
    opacity: 0.0;
    transform: translateX(-190%) scale(.8);
}
.device1-enter-to,
.device2-enter-to 
.device3-enter-to {
    opacity: 1.0;
}
</style>